import React from "react";
import { coverageDetails } from "../../utils/constant";
import { imagePath } from "../../utils/sharedFunction";
import _ from "lodash";

function CoverageList(props) {
  return (
    <>
      <h2
        style={{ fontSize: "20px", marginBottom: "-0.75rem" }}
        className="info-hud"
      >
        Benefits
      </h2>
      {props.coveragesElected && (
        <ul className="list-group">
          {props.coveragesElected.sort().map((coverage, index) => (
            <li className="list-group-item">
              <div key={index} className="row mt-4 pb-2">
                <div className="col-lg-2 col-md-1 col-sm-2">
                  <img
                    src={
                      coverageDetails[coverage] &&
                      imagePath(coverageDetails[coverage].url)
                    }
                    alt=""
                    className="data-icon"
                  />
                </div>
                {/* {console.log(coverageDetails[coverage].value ,"NY",props.checkState)} */}
                <div className="col-lg-10 col-md-11 col-sm-10">
                  {_.get(props, "checkState") === "NY" ? (
                    <div className="data-label">
                      {coverageDetails[coverage] &&
                      coverageDetails[coverage].value ===
                        "Basic Critical Illness"
                        ? "Specified Disease"
                        : coverageDetails[coverage].value ===
                          "Voluntary Critical Illness"
                        ? "Voluntary Specified Disease"
                        : coverageDetails[coverage].value}
                    </div>
                  ) : (
                    <div className="data-label">
                      {coverageDetails[coverage] &&
                        coverageDetails[coverage].value}
                    </div>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}

export default CoverageList;
