import React, { Component } from 'react';
import _ from "lodash";
import { history } from '../../utils/constant';
import FocusTrap from "focus-trap-react";

class DuplicatePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            duplicateSsn: false
        }
    }

    onSubmit = (e) => {
        let duplicateSsn = this.state.duplicateSsn
        if (duplicateSsn === "missingInformation" || duplicateSsn === "annualEnrollment" || duplicateSsn === "qualifyingEvent" || duplicateSsn === "previousApplication") {
            if (_.get(this.props, "isTrusted", "") === true) {
                history.push(`/eoi/reviewWhoAreWeCovering`)
            }
            else {
                this.props.handleDuplicate();
            }
        }

        else {
            if (duplicateSsn === "previouslyDeclined") {
                history.push(`/eoi/PreviouslyDeclined`)
            }
            if (duplicateSsn === "applicationClosed") {
                history.push(`/eoi/DuplicateSSN`)
            }
            if (duplicateSsn === "noDecision") {
                history.push(`/eoi/nodecision`)
            }
        }
    }

    onCheckBoxChange = (e, name) => {
        this.setState({ duplicateSsn: name })
    }

    render() {
        return (
            <>
                <FocusTrap>
                    <div className="popup-bg">
                        <div className="popup-box small" role='dialog' aria-label='Prior Application Activity Dialogue' aria-labelledby='prior-activity-dialog-title' style={{ width: "65rem" }}>
                            <div className="popup-header">
                                <button className="close hidden-text">
                                    <i className="fas fa-times"><span>close button</span></i>
                                </button>
                            </div>
                            <div className="container check-popup">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2
                                            id="prior-activity-dialog-title"
                                            style={{
                                                fontSize: "1.875rem",
                                                lineHeight: "2.125rem",
                                                marginInlineStart: "0px",
                                                marginInlineEnd: "0px",
                                                unicodeBidi: "isolate"
                                            }}
                                        >
                                            Prior Application Activity
                                        </h2>
                                        <p className="lead mt-4" style={{ color: "#0d3f5e" }}>It seems that you may have already submitted an Evidence of Insurability application in the past 90 days. Let us know why you are submitting a new application:</p>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <input type="radio" class="radio" onChange={(e) => this.onCheckBoxChange(e, "missingInformation")} name={"dup"} style={{ marginLeft: "-180px", height: "22px", width: "36px", display: "inline" }} />
                                        <label htmlFor={"missingInformation"} style={{ fontSize: "20px", marginLeft: "10px" }}>  I received a request to resubmit my application with additional/missing information.</label>
                                        <br />
                                        <input type="radio" class="radio" onChange={(e) => this.onCheckBoxChange(e, "annualEnrollment")} name={"dup"} style={{ marginLeft: "-130px", height: "22px", width: "39px", display: "inline" }} />
                                        <label htmlFor={"annualEnrollment"} style={{ fontSize: "20px", marginLeft: "10px" }}>  I am adding new coverages or amending existing coverages as a part of annual enrollment.</label>
                                        <br />
                                        <input type="radio" class="radio" onChange={(e) => this.onCheckBoxChange(e, "qualifyingEvent")} name={"dup"} style={{ marginLeft: "-143px", height: "22px", width: "39px", display: "inline" }} />
                                        <label htmlFor={"qualifyingEvent"} style={{ fontSize: "20px", marginLeft: "10px" }}>  I am adding new coverages or amending existing coverages due to a qualifying life event.</label>
                                        <br />
                                        <input type="radio" class="radio" onChange={(e) => this.onCheckBoxChange(e, "previousApplication")} name={"dup"} style={{ marginLeft: "-270px", height: "22px", width: "39px", display: "inline" }} />
                                        <label htmlFor={"previousApplication"} style={{ fontSize: "20px", marginLeft: "10px" }}>  I need to add or amend medical information from my previous application.</label>
                                        <br />
                                    </div>
                                    <div>
                                        <input type="radio" class="radio" onChange={(e) => this.onCheckBoxChange(e, "previouslyDeclined")} name={"dup"} style={{ marginLeft: "-195px", height: "22px", width: "39px", display: "inline" }} />
                                        <label htmlFor={"previouslyDeclined"} style={{ fontSize: "20px", marginLeft: "10px" }}>  I was previously declined for coverage and want to reapply for the same coverages </label>
                                        <br />
                                    </div>
                                    <div>
                                        <input type="radio" class="radio" onChange={(e) => this.onCheckBoxChange(e, "applicationClosed")} name={"dup"} style={{ marginLeft: "-180px", height: "22px", width: "39px", display: "inline" }} />
                                        <label htmlFor={"applicationClosed"} style={{ fontSize: "20px", marginLeft: "10px" }}>  I requested to have my previous application closed, but decided I still want coverage </label>
                                        <br />
                                    </div>
                                    <div>
                                        <input type="radio" class="radio" onChange={(e) => this.onCheckBoxChange(e, "noDecision")} name={"dup"} style={{ marginLeft: "-352px", height: "22px", width: "39px", display: "inline" }} />
                                        <label htmlFor={"noDecision"} style={{ fontSize: "20px", marginLeft: "10px" }}>  I have not received a decision back from my previous application </label>
                                    </div>
                                </div>
                                <div className="field-group mt-3 mb-3">
                                    <div><button className="btn btn-primary m-2" onClick={(e) => this.onSubmit(e)}> Submit </button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FocusTrap>
            </>
        )
    }
}
export default DuplicatePopup;