import React, { Component } from "react";
import FocusTrap from "focus-trap-react";

// const restrictShiftTabKeyNav = (event) => {
//     const { keyCode } = event;
//     if (event.shiftKey && keyCode === 9) {
//         event.preventDefault();
//     }
// }

// const restrictTabKeyNav = (event) => {
//     const { keyCode } = event;
//     if (keyCode === 9) {
//         event.preventDefault();
//     }
// }
class SaveForLater extends Component {

    render() {
        return (
            <>
                <FocusTrap>
                    <div className="popup-bg">
                        <div className="popup-box small" role="dialog" aria-label="We'll be here for you" aria-labelledby="here-for-you-title" aria-describedby="here-for-you-description">
                            <div className="popup-header">
                                <button className="close hidden-text">
                                    <i className="fas fa-times"><span>close button</span></i>
                                </button>
                            </div>
                            <div className="container here-for-you-popup">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="h3" id="here-for-you-title">We'll be here for you!</h2>
                                        <h3 className="h6" id="here-for-you-description">We'll save your progress but you're well on your way to a decision. Would you like to continue?</h3>
                                    </div>
                                </div>
                                <div className="field-group mt-5">
                                    <div className="item-1"><button id="continue-button" className="btn btn-outline" onClick={this.props.continueEOI}>Continue EOI</button></div>
                                    <div className="item-2"><button className="btn btn-primary" onClick={this.props.leave}>Leave</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FocusTrap>
            </>
        );
    }
}
export default SaveForLater;