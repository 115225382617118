import React from "react";
import { imagePath } from "../../utils/sharedFunction";

function WhoIsGettingCovered(props) {
  return (
    <>
      <h2 style={{ fontSize: "20px" }} className="info-hud">
        Who’s getting covered
      </h2>
        <div className="row pb-4">
          <div className="col-lg-2 col-md-1 col-sm-2">
            <img
              src={imagePath("./images/family-icon.svg")}
              alt=""
              className="data-icon"
            />
          </div>
          <div className="col-lg-10 col-md-11 col-sm-10">
            <div>
              <div>
                <h3 className="data-label">{props.gettingCovered}</h3>
                {props.spouseCovered && (
                  <div className="tips-info">
                    <div
                      className="itext"
                      onClick={() => props.onBenefitPopup()}
                    >
                      <i className="fas fa-info-circle"></i>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="data-info">
              {props.employeeCovered && (
                <dl>
                  <dt className="mt-4">
                    {props.gettingCovered === "Me, my spouse, my child(ren)" ||
                    props.gettingCovered === "Me, my spouse" ||
                    props.gettingCovered === "Me, my child(ren)" ||
                    props.gettingCovered === "My spouse, my child(ren)" ? (
                      <p>
                        <strong>Me</strong>
                      </p>
                    ) : null}
                  </dt>
                  <dd>{props.employeeName}</dd>
                </dl>
              )}
              {props.spouseCovered && (
                <dl>
                  <dt className="mt-3">
                    {props.gettingCovered === "Me, my spouse, my child(ren)" ||
                    props.gettingCovered === "Me, my spouse" ||
                    props.gettingCovered === "Me, my child(ren)" ||
                    props.gettingCovered === "My spouse, my child(ren)" ? (
                      <p>
                        <strong>My spouse</strong>
                      </p>
                    ) : null}
                  </dt>
                  <dd>{props.spouseName}</dd>
                </dl>
              )}
              {props.childCovered && (
                <dl>
                  <dt className="mt-3">
                    {props.gettingCovered === "Me, my spouse, my child(ren)" ||
                    props.gettingCovered === "Me, my spouse" ||
                    props.gettingCovered === "Me, my child(ren)" ||
                    props.gettingCovered === "My spouse, my child(ren)" ? (
                      <p>
                        <strong>My child(ren)</strong>
                      </p>
                    ) : null}
                  </dt>
                  {props.childrenNames &&
                    props.childrenNames.map((child, index) => (
                      <dd key={index}>
                        {child &&
                        child.middle_initial &&
                        child.middle_initial.length > 0
                          ? child.first_name +
                            " " +
                            child.middle_initial +
                            " " +
                            child.last_name
                          : child.first_name + " " + child.last_name}
                      </dd>
                    ))}
                </dl>
              )}
            </div>
          </div>
        </div>
    </>
  );
}

export default WhoIsGettingCovered;
