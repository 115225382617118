import React, { Component } from 'react';
import FocusTrap from "focus-trap-react";

const restrictTabKeyNav = (event) => {
    const { keyCode } = event;
    if ((event.shiftKey && keyCode === 9) || (keyCode === 9)) {
        event.preventDefault();
    }
}
class CheckPopUp extends Component {

    render() {
        return (
            <>
            <FocusTrap>
                    <div className="popup-bg">
                        <div className="popup-box small" role='dialog' aria-label='Please Double Check Popup' aria-labelledby='please-double-check-title'>
                            <div className="popup-header">
                                <button className="close hidden-text">
                                    <i className="fas fa-times"><span>close button</span></i>
                                </button>
                            </div>
                            <div className="container check-popup">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 id='please-double-check-title' style={{fontSize: "1.875rem", lineHeight: "2.125rem"}}>Please double check....</h2>
                                        <p className="lead mt-4" style={{ color: "#0d3f5e" }}>Once you submit, you won't be able to make further changes to your personal information on this site.</p>
                                    </div>
                                </div>
                                <div className="field-group mt-3 mb-3">
                                    <div><button className="btn btn-primary m-2" onClick={this.props.handlePopUp} onKeyDown={restrictTabKeyNav} > OK </button></div>
                                    {/* <div><button className="btn btn-primary float-right" onClick={this.props.cancelPopup}>Cancel</button></div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </FocusTrap>
            </>
        )
    }
}
export default CheckPopUp


