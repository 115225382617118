import React, {Component} from 'react';
class AlertBanner extends Component{

  
    render(){
        
        return(
            <>
            <div className="banner-margin showbanner">
                <div role="alert" className="alert-banner warning">
                    <button aria-label='Alert Banner Close' className="close hidden-text" onClick={this.props.handleClose}
                    >
                        <i className="fas fa-times"> <span>Close</span> </i>
                    </button>

                    <span role="alert" className="alert-msg"><i role="img" aria-label="error" className="fas fa-exclamation-triangle"></i>{this.props.errorMessage}</span>

                </div>
            </div>
        </>   
        );
    }
}
// export default props => (
//     <>
//         <div className="banner-margin showbanner">
//             <div class="alert-banner warning">
//                 <button className="close hidden-text">
//                     <i className="fas fa-times"> <span>Close</span> </i>
//                 </button>
//                 <span className="alert-msg"><i class="fas fa-exclamation-triangle"></i>Please fill out all required fields.</span>
//             </div>
//         </div>
//     </>
// );
export default AlertBanner