import React, { Component } from 'react';
import { imagePath } from '../../utils/sharedFunction';

import { actionCreators } from "../../store/HomePage/HomePageAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class ExternalWorkdayNoBenefits extends Component {
    render() {
        this.props.workdayCheck(true);
        // const paraSearch = new URLSearchParams(window.location.search);
        // const source = paraSearch.get("source");
        return (
            <>
                <main>
                    <div className="container" style={{ textAlign: "center" }}>
                        <div className="row">
                            <div className="col-lg-7 col-md-8">
                                <div className="intro">
                                    <h3>We couldn't find any benefits requiring an evidence of insurability form.</h3>

                                    <h6 className="pt-2" style={{ color: '#00728D' }}>
                                        If you'd like to change your benefits, you can do that in Workday.
                                    </h6>
                                    <p className="lead mt-3" style={{ color: '#0D3F5E' }}>
                                        Please remember to select <b>Submit</b> under your to do task to complete the evidence of insurability process.
                                    </p>
                                    {/* {source.toLowerCase() === 'gwdy' ?  */}
                                    <p className="lead mt-3" style={{ color: '#0D3F5E' }}>
                                        For questions, please contact <b>your HR</b>
                                    </p>
                                    {/* :<p className="lead mt-3" style={{ color: '#0D3F5E' }}>
                                        For questions, please contact your HR.
                                    </p>} */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={imagePath("./images/landingpage-illustration.svg")} className="illustration-bg" alt="illustration-bg" />
                </main>
            </>
        )
    }
}

export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch)) (ExternalWorkdayNoBenefits);
