import React, { Component } from "react";

import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AlertBanner from "../../../components/AlertBanner/AlertBanner";
import { actionCreators } from "../../../store/HomePage/HomePageAction";
import {
    moveToPreviousQuestionFromCondition,
    scrollUp,
    imagePath
} from "../../../utils/sharedFunction";
import Warning from "../../../components/Warning/Warning";
import SaveForLater from "../../../components/SaveForLater/SaveForLater";

class ConditionQ extends Component {
    state = {
        conditionData: {},
        showTextBox: false,
        otherCondition: "",
        displayBanner: false,
        isSaved: false,
        isOpened: false
    }
    componentDidMount() {
        let data = _.get(this.props, "location.state.condition")
  
        this.setState({
            conditionData: data
        })
        const lastDisclosureData = data.life_entities_dq.disclosure_types && data.life_entities_dq.disclosure_types[data.life_entities_dq.disclosure_types.length - 1]
        if (lastDisclosureData && (lastDisclosureData.disclosure_desc.toLowerCase().includes("other") || lastDisclosureData.disclosure_desc.toLowerCase().includes("i was prescribed a different medication that does not relate to any of the above categories")|| lastDisclosureData.disclosure_desc.toLowerCase().includes("i consulted a medical professional for reasons not listed above")) && lastDisclosureData.answer_disclosure_info_value
            && lastDisclosureData.answer_disclosure_info) {
            this.setState({
                showTextBox: true
            })
        }
        else {
            this.setState({
                showTextBox: false
            })
        }
        scrollUp()
        window.addEventListener("popstate", () => {
            this.props.history.goForward();
            this.props.bClick(true);
        })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.match.params !== this.props.match.params) {
            //console.log(this.props)
            let data = _.get(this.props, "location.state.condition")

            this.setState({
                conditionData: _.get(this.props, "location.state.condition")
            })
            const lastDisclosureData = data.life_entities_dq.disclosure_types && data.life_entities_dq.disclosure_types[data.life_entities_dq.disclosure_types.length - 1]
            if (lastDisclosureData && (lastDisclosureData.disclosure_desc.toLowerCase().includes("other") || lastDisclosureData.disclosure_desc.toLowerCase().includes("i was prescribed a different medication that does not relate to any of the above categories") || lastDisclosureData.disclosure_desc.toLowerCase().includes("i consulted a medical professional for reasons not listed above")) && lastDisclosureData.answer_disclosure_info_value && lastDisclosureData.answer_disclosure_info) {
                this.setState({
                    showTextBox: true
                })
            }
            else {
                this.setState({
                    showTextBox: false
                })
            }
            scrollUp()
        }
    }
    handleBack = () => {
        //const activeQuestion = _.get(this.props,"activeQuestion","")
        //this.props.history.push(`/eoi/question/${activeQuestion}`)
        let selected = 0;
        selected = document.querySelectorAll('input[type="checkbox"]:checked').length;
        let reviewInd = _.get(this.props, "prefillData.eoi_data.health_question_info.review_hq_enabled", "")

        //  console.log(selected,"check")
        if (selected === 0 && reviewInd === "Y") {
            this.setState({
                displayBanner: true
            })
        } else {
            moveToPreviousQuestionFromCondition(this.props)
        }
        // this.handleCheckboxSelection();    
    }
    handleBrowser = () => {
        this.props.bClick(false);
    }
    handleNext = () => {

        let allDisclosureStatus = []
        this.state.conditionData.life_entities_dq.disclosure_types && this.state.conditionData.life_entities_dq.disclosure_types.map(disclosure => {
            allDisclosureStatus.push(disclosure.answer_disclosure_info)
            return disclosure
        })
        const lastDisclosureData = this.state.conditionData.life_entities_dq.disclosure_types && this.state.conditionData.life_entities_dq.disclosure_types[this.state.conditionData.life_entities_dq.disclosure_types.length - 1]


        if (allDisclosureStatus && allDisclosureStatus.includes(true) === false) {
            this.setState({
                displayBanner: true
            })
        }
        else if (lastDisclosureData && (lastDisclosureData?.disclosure_desc.toLowerCase().includes("other") || lastDisclosureData.disclosure_desc.toLowerCase().includes("i was prescribed a different medication that does not relate to any of the above categories") || lastDisclosureData?.disclosure_desc.toLowerCase().includes("i consulted a medical professional for reasons not listed above")) && lastDisclosureData.answer_disclosure_info === true &&
            !lastDisclosureData.answer_disclosure_info_value) {
            this.setState({
                displayBanner: true
            })
        }
        else {
            const activeQuestion = _.get(this.props, "activeQuestion", 0)
            //const activeCondition = _.get(this.props,"activeCondition","")
            const baseQuestionDet = _.get(this.props, "prefillData.eoi_data.health_question_info.base_question", [])
            const baseQuestion = JSON.parse(JSON.stringify(baseQuestionDet))
            baseQuestion.map(question => {
                //console.log(question)
                if (question.question_id.toString() === activeQuestion.toString()) {
                    question.health_condition.map(condition => {
                        if (condition.condition_id === this.state.conditionData.condition_id) {
                            condition.condition_page_status = "ACTIVE"
                            condition.life_entities_dq.map(life => {
                                if (life.life_id === this.state.conditionData.life_entities_dq.life_id) {
                                    if (life.disclosure_types && this.state.conditionData.life_entities_dq.disclosure_types) {
                                        life.disclosure_types = this.state.conditionData.life_entities_dq.disclosure_types
                                    }

                                }
                                return life
                            })
                        }
                        else {
                            condition.condition_page_status = "INACTIVE"
                        }
                        return condition
                    })
                }
                else {
                    question.health_condition && question.health_condition.map(condition => {
                        condition.condition_page_status = "INACTIVE"
                        return condition
                    })
                }

                return question
            })
            let noChecked = 0;
            noChecked = document.querySelectorAll('input[type="checkbox"]:checked').length;
            // console.log(noChecked,"NOChecked");
            this.props.rCount(noChecked);
            // console.log(this.props.refCount,"refCount")
            _.set(this.props, "prefillData.eoi_data.health_question_info.base_question", baseQuestion)
            if (_.get(this.props, "location.state.previousPage") === "review") {
                _.set(this.props, "location.state.previousPage", "review")
                this.props.saveConditionQuestion(_.get(this.props, "prefillData", {}), this.props)
            } else {
                this.props.saveConditionQuestion(_.get(this.props, "prefillData", {}), this.props)
            }

            //moveToReflexiveQuestion(this.props)

        }


    }
    handleCheckboxSelection = (e) => {
        let conditionData = { ...this.state.conditionData }
        conditionData.life_entities_dq.disclosure_types.map(disclosure => {
            if (disclosure.disclosure_id === e.target.id) {
                disclosure.answer_disclosure_info = e.target.checked
                disclosure.disclosure_info_required = true
            }
            if (conditionData.life_entities_dq.disclosure_types.length.toString() === e.target.id && (disclosure.disclosure_desc.toLowerCase().includes("other") || disclosure.disclosure_desc.toLowerCase().includes("i was prescribed a different medication that does not relate to any of the above categories")|| disclosure.disclosure_desc.toLowerCase().includes("i consulted a medical professional for reasons not listed above"))) {
                if (e.target.checked === true) {
                    this.setState({
                        showTextBox: true
                    })
                }
                else {
                    disclosure.answer_disclosure_info_value = ""
                    this.setState({
                        showTextBox: false
                    })
                }
            }
            return disclosure
        })
        let allDisclosureStatus = []
        this.state.conditionData.life_entities_dq.disclosure_types && this.state.conditionData.life_entities_dq.disclosure_types.map(disclosure => {
            allDisclosureStatus.push(disclosure.answer_disclosure_info)
            return disclosure
        })

        if (allDisclosureStatus.includes(true) === true) {
            this.setState({
                displayBanner: false
            })
        }

        this.setState({
            conditionData
        })
    }
    handleTextChange = (e) => {
        let conditionData = { ...this.state.conditionData }

        this.setState({
            otherCondition: e.target.value.trimStart()
        })
        conditionData.life_entities_dq.disclosure_types.map(disclosure => {
            if (conditionData.life_entities_dq.disclosure_types.length.toString() === e.target.id) {
                if(!disclosure.disclosure_desc.toLowerCase().includes("anemia, other")){
                    if (disclosure.disclosure_desc.toLowerCase().includes("other") || disclosure.disclosure_desc.toLowerCase().includes("i consulted a medical professional for reasons not listed above") || disclosure.disclosure_desc.toLowerCase().includes("i was prescribed a different medication that does not relate to any of the above categories")) {
                        if (disclosure.answer_disclosure_info === true) {
                            disclosure.answer_disclosure_info_value = e.target.value.trimStart()
                        }
                    }
                }
            }
            return disclosure
        })
        this.setState({
            conditionData
        })

        const lastDisclosureData = conditionData.life_entities_dq.disclosure_types && conditionData.life_entities_dq.disclosure_types[this.state.conditionData.life_entities_dq.disclosure_types.length - 1]

        // let allDisclosureStatus = []

        // if(this.state.conditionData.life_entities_dq.disclosure_types.map(disclosure => {
        //     allDisclosureStatus.push(disclosure.answer_disclosure_info )

        // }))
        if (lastDisclosureData && (lastDisclosureData.disclosure_desc.toLowerCase().includes("other") || lastDisclosureData.disclosure_desc.toLowerCase().includes("i consulted a medical professional for reasons not listed above") || lastDisclosureData.disclosure_desc.toLowerCase().includes("i was prescribed a different medication that does not relate to any of the above categories")) && lastDisclosureData.answer_disclosure_info === true && e.target.value.trim().length > 0) {
            this.setState({
                displayBanner: false
            })
        }

    }
    handleClose = () => {
        this.setState({
            displayBanner: false
        })
    }
    displayFullName = () => {
        //let name,first_name,last_name,middle_initial
        if (this.state.conditionData && this.state.conditionData.life_entities_dq) {

            let name = this.state.conditionData.life_entities_dq.life_name.split(', ')
            let first_name = ""
            let last_name = ""
            let middle_initial = ""
            if (_.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.source", "") === "GWDY") {
                first_name = name.slice(-1);
                last_name = name.length === 3 ? name.slice(0, 2) : name.slice(0, 1)
            } else {
                first_name = name[1]
                last_name = name[0]
                middle_initial = name[2] ? name[2] : null
            }
            return (first_name + " " + (middle_initial ? (middle_initial + " ") : "") + last_name)

        }
    }
    displaySubHeader = () => {
        let condition1 = ["heart", "liver", "kidney", "respiratory", "lung", "digestive", "mental", "nervous", "emotional", "immune", "diabetes", "cancer", "stroke", "neurological"]
        let condition2 = ["drugs", "Medication other than as prescribed", "Alcoholism", "Alcohol"]
        let condition3 = ["back", "neck", "spine", "arthritis", "muscular", "skeletal"]
        let condition4 = ["consultation", "prescribed medications", "diagnostic"]
        let condition5 = ["arc", "aids", "hiv"]
        let conditionData = _.get(this.props, "location.state.condition", {})
        // console.log(conditionData.condition_name.toLowerCase().includes("prescribed"),"cond",conditionData)
        for (let i = 0; i < condition1.length; i++) {
            if (conditionData.condition_name.toLowerCase().includes(condition1[i])) {
                let state = _.get(this.props, "prefillData.eoi_data.group_info.group_situs_state")
                //console.log("state",state)

                if (state === "MD") {
                    return <p className="col-lg-6">You indicated that you have been treated for or diagnosed with the following conditions <strong>in the past 7 years</strong>. Select all that apply.</p>

                }
                if (state === "IN" || state === "PA") {
                    return <p className="col-lg-6">You indicated that you have been treated for or diagnosed with the following conditions <strong>in the past 5 years</strong>. Select all that apply.</p>

                }
                else {
                    return <p className="col-lg-6">You indicated that you have been treated for or diagnosed with the following conditions <strong>in the past 10 years</strong>. Select all that apply.</p>
                }

            }
        }
        for (let i = 0; i < condition2.length; i++) {
            if (conditionData.condition_name.includes(condition2[i])) {
                return <p className="col-lg-6">You indicated use of the following <strong>in the past 5 years</strong>.</p>
            }
        }
        for (let i = 0; i < condition3.length; i++) {
            if (conditionData.condition_name.toLowerCase().includes(condition3[i])) {
                return <p className="col-lg-6">You indicated that you have been treated for the following conditions <strong>in the past 5 years</strong>. Select all that apply.</p>
            }
        }
        for (let i = 0; i < condition4.length; i++) {
            if (conditionData.condition_name.toLowerCase().includes(condition4[0])) {
                return <p className="col-lg-6">You indicated <strong>in the past 12 months </strong> you sought treatment or <b>consultation in a hospital or health care facility.</b> Tell us about the conditions you sought medical attention for.</p>
            }
            if (conditionData.condition_name.toLowerCase().includes(condition4[1])) {
                return <p className="col-lg-6">You indicated that <strong>in the past 12 months</strong> you were <b>prescribed medication</b> not mentioned in the previous questions. Tell us about your  prescribed medications and their prescribed uses.</p>
            }
            if (conditionData.condition_name.toLowerCase().includes(condition4[i])) {
                return <p className="col-lg-6">You indicated that <strong>in the past 12 months</strong> you received diagnostic testing. Select all that apply.</p>
            }
        }
        for (let i = 0; i < condition5.length; i++) {
            //console.log(conditionData.condition_name.toLowerCase().includes(condition5[i]))

            if (conditionData.condition_name.toLowerCase().includes(condition5[i])) {
                let state = _.get(this.props, "prefillData.eoi_data.group_info.group_situs_state")
                if (state === 'FL') {
                    return <p className="col-lg-6">You indicated you have tested positive for the following condition(s). Select all that apply.</p>
                }
                if (state === "CA") {
                    return <p className="col-lg-6">You indicated you have been treated or diagnosed for the following condition(s). Select all that apply.</p>
                }
                if (state === "NY") {
                    return <p className="col-lg-6">You indicated you have been treated or diagnosed for the following condition(s). Select all that apply.</p>
                }
            }

        }


    }

    ismedicationEmpty = (spouseDetails) => {
        for (var i in spouseDetails) return false;
        return true;
    }
    saveForLater = () => {
        this.setState({ isSaved: true });
    }
    continueEOI = () => {
        this.setState({ isSaved: false });
    }
    leave = () => {
        this.props.history.push({ pathname: `/eoi/success` })
    }
    medicationDisclosure = (disclosure) => {
        const disclosureSplit = disclosure.split(" ");
        if(disclosureSplit[0] ==="Birth") {
            const result = disclosureSplit.slice(0,4).join(" ") + " ";
            return  <><span  style={{fontWeight:"bold"}}>{result}</span><span>{disclosureSplit.slice(4).join(" ")}</span></>
        } 
        if(disclosureSplit[0] === "Diabetic" || disclosureSplit[0] === "Mental," ){
            const result = disclosureSplit.slice(0,6).join(" ") + " ";
            return  <><span  style={{fontWeight:"bold"}}>{result}</span><span>{disclosureSplit.slice(6).join(" ")}</span></>  
        }
        else {
            const result = disclosureSplit.slice(0,2).join(" ") + " ";
            return <><span  style={{fontWeight:"bold"}}>{result}</span><span>{disclosureSplit.slice(2).join(" ")}</span></>
        }
    }

    render() {
        const { conditionData } = this.state
        // console.log(conditionData,"data")
        return (
            <>
                {this.state.isSaved === true ? <SaveForLater continueEOI={() => this.continueEOI()} leave={() => this.leave()} /> : null}
                {this.props.backClick === true ? <Warning handleBrowser={() => this.handleBrowser()} /> : null}
                {this.state.displayBanner === true ?
                    <AlertBanner handleClose={() => this.handleClose()} errorMessage="Please select at least one condition." />
                    :
                    null
                }
                <main className="knockout">
                    <div className="row question-bg q-mobile-bg mb-3">
                        <div className="container-fluid">
                            <div className="prev-link">
                                <button className="btn btn-link" onClick={() => this.handleBack()}>
                                    <i className="fa fa-arrow-left"></i>Health question {_.get(this.props, "activeQuestion", "")}
                                </button>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <img src={imagePath("./images/Employer-Gender-Neutral.svg")} alt="" className="employer-neutral-icon float-left" />
                                    <h3 className='header-icon-padding'>{this.displayFullName()}</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            {this.displaySubHeader()}
                            {/* <p className="col-lg-6">
                        You indicated that you have been treated for or diagnosed with the
                            following conditions <strong>in the past 10 years.</strong> Select all that apply.
                        </p> */}
                        </div>
                        <div className="row">
                            <div className="col-lg-6 mt-4">
                                {!this.ismedicationEmpty(conditionData) && conditionData && conditionData.condition_name && conditionData.condition_name.length > 0 &&
                                    (conditionData.condition_name.includes("HIV") || conditionData.condition_name.includes("AIDS") || conditionData.condition_name.includes("ARC") || conditionData.condition_name.includes("Consultation") || conditionData.condition_name.includes("Prescribed medication"))
                                    ?
                                    null
                                    :
                                    <h6>{conditionData && conditionData.condition_name}</h6>
                                }
                                {conditionData && conditionData.condition_name === "Illegal drugs" ?
                                    <p className="mt-4">Select the drugs you used.</p>
                                    :
                                    conditionData && conditionData.condition_name && conditionData.condition_name.includes("Alcoholism") ?
                                        <p className="mt-4">Select your condition.</p>
                                        :
                                        conditionData && conditionData.condition_name && conditionData.condition_name.includes("Medication other than as prescribed") ?
                                            <p className="mt-4">Tell us about the medications you use other than as prescribed.</p>
                                            :
                                            conditionData?.condition_name?.includes("Acquired Immune Deficiency Syndrome (AIDS) or AIDS Related Complex (ARC)") ?
                                                <p style={{ marginTop: "-25px" }}>Test results received at an anonymous counseling and test site need not be revealed on this application, nor do the results of a home test kit.
                                                </p> :
                                                null}
                                <div className="from-group">
                                    {conditionData && conditionData.life_entities_dq &&
                                        conditionData.life_entities_dq.disclosure_types && conditionData.life_entities_dq.disclosure_types.map((disclosure, index) => (
                                            (disclosure.disclosure_desc.includes("Cardiovascular/Circulatory") || disclosure.disclosure_desc.includes("Nervous or Psychological:") || disclosure.disclosure_desc.includes("Endocrine") || disclosure.disclosure_desc.includes("Gastrointestinal") || disclosure.disclosure_desc.includes("Musculoskeletal") || disclosure.disclosure_desc.includes("Respiratory") || disclosure.disclosure_desc.includes("Urinary:") || (disclosure.disclosure_desc.includes("Other:") && conditionData.condition_name.includes("Consultation in hospital or health care facility") ))
                                                ? <label htmlFor={disclosure.disclosure_id} className="mt-3" style={{ marginLeft: "2rem" }} >
                                                    {disclosure.disclosure_desc}                                                    
                                                </label> : ( disclosure.disclosure_desc.includes("Yasmin") ||  disclosure.disclosure_desc.includes("Lisinopril") || disclosure.disclosure_desc.includes("Lipitor") || disclosure.disclosure_desc.includes("Albuterol") || disclosure.disclosure_desc.includes("Metformin") || disclosure.disclosure_desc.includes("Prilosec") || disclosure.disclosure_desc.includes("limited to:") || disclosure.disclosure_desc.includes("Synthroid") || disclosure.disclosure_desc.includes("Other:") || disclosure.disclosure_desc.includes("NSAIDs") || disclosure.disclosure_desc.includes("Adderall") ) ? <>
                            {/* disclosure.disclosure_desc.includes("Antihypertensive medication") || */}
                                                    <span htmlFor={disclosure.disclosure_id} className="mt-3" style={{color:"#0d3f5e"}} >
                                                        {/* disclosure.disclosure_desc.includes("Antihypertensive medication") */}
                                                        {  disclosure.disclosure_desc.includes("limited to:") || disclosure.disclosure_desc.includes("Other:")  ? this.medicationDisclosure(disclosure.disclosure_desc) :" "+disclosure.disclosure_desc }
                                                    </span>
                                                </>
                                                    : <div className="checkbox" key={index}>
                                                        <input type="checkbox" id={disclosure.disclosure_id}
                                                            onChange={(e) => this.handleCheckboxSelection(e)}
                                                            checked={disclosure.answer_disclosure_info === true}
                                                        />
                                                        <label htmlFor={disclosure.disclosure_id}>
                                                            {disclosure.disclosure_desc}
                                                        </label>
                                                    </div>

                                        ))
                                    }
                                    {this.state.showTextBox === true ?
                                        <div className="form-group other-textinput">
                                            <input className="form-control" aria-labelledby='other' maxLength={100}
                                                value={conditionData.life_entities_dq.disclosure_types[conditionData.life_entities_dq.disclosure_types.length - 1].answer_disclosure_info_value}
                                                id={conditionData.life_entities_dq.disclosure_types.length} type="text" required onChange={(e) => this.handleTextChange(e)} />
                                        </div>
                                        :
                                        null}
                                    {/* {(conditionData.condition_name === "Consultation in hospital or health care facility" || conditionData.condition_name === "Prescribed medications") ? <><h6 className='header-icon-padding' style={{ marginLeft: "33px" }}>Need to add a different medication?</h6> <div className="form-group other-textinput">
                                    <input className="form-control" aria-labelledby='other' maxLength={100}
                                        value={conditionData.life_entities_dq.disclosure_types[conditionData.life_entities_dq.disclosure_types.length - 1].answer_disclosure_info_value}
                                        id={conditionData.life_entities_dq.disclosure_types.length} type="text" required onChange={(e) => this.handleTextChange(e)} />
                                </div></> : null} */}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <hr />
                                <div className="field-group table-header-group">
                                    {_.get(this.props, "trustedFlag", "") === true ?
                                        <button className="btn btn-link" onClick={() => this.saveForLater()}>
                                            Save for later
                                        </button>
                                        :
                                        null
                                    }
                                    <button className="btn btn-primary float-right" onClick={() => this.handleNext()}>
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

// export default ConditionQ;
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(ConditionQ);
