import React, { Component } from 'react';
import axios from "axios";
import { actionCreators } from "../../store/HomePage/HomePageAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { trackPromise } from "react-promise-tracker";
class OktaToEOI extends Component {
    componentDidMount() {
        console.log("componentDidMount");
        this.props.workdayCheck(true);
        trackPromise(axios.post('/eoi/workdayPage',{ "request": "request" })
        .then(res => {
            console.log('Response', res);
            window.location.href = res.data;
          })
          .catch(error => {
            console.log(error);
          }));
      }
   
    render() {
        console.log("render");
       
        this.props.workdayCheck(true);

       
        return (
            <>
                <main>
                    <div className="container" style={{ textAlign: "center" }}>
                        <div className="row">
                            <div className="col-lg-7 col-md-8">
                                <div className="intro">
                                    <h3>Evidence of Insurability Loading..</h3>

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch)) (OktaToEOI);
